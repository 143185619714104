<template>
    <div class="home_page">
        <home-banner></home-banner>
        <div class="home-news-module home-module">
            <div class="home-title">
                <p class="title1">新闻动态</p>
                <p class="title2">News</p>
            </div>
            <home-news></home-news>
        </div>  
        <div class="home-research-module home-module">
            <div class="home-title">
                <p class="title1">研究成果</p>
                <p class="title2">Research Achievements</p>
            </div>
            <home-research></home-research>
        </div>
        <div class="home-expert-module home-module">
            <div class="home-title">
                <p class="title1">专家团队</p>
                <p class="title2">Experts Team</p>
            </div>
            <home-expert></home-expert>
        </div>
        <div class="home-partner-module home-module">
            <div class="home-title">
                <p class="title1">合作伙伴</p>
                <p class="title2">Partnership</p>
            </div>
            <div class="partner-content">
                <template v-for="(item,index) in 8">
                    <img :src="'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/co'+(index+1)+'.png'" :key="index">
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import HomeBanner from "../block/Banner.vue"
import HomeNews from "../block/News.vue"
import HomeResearch from "../block/Research.vue"
import HomeExpert from "../block/Expert.vue"
export default {
    name:'industrycollegeHomepage',
    components:{
        HomeBanner,
        HomeNews,
        HomeResearch,
        HomeExpert
    },
    data(){
        return {
        }
    },
}
</script>
<style lang="less" scoped>
.home_page{
    font-size: 20px;
    line-height: 28px;
    color:#333333;
    .home-title{
        text-align: center;
        padding-top:60px;
        .title1{
            font-size:30px;
            line-height: 38px;
            color:#333333;
            font-weight: 550;
        }
        .title2{
            font-size:24px;
            line-height: 32px;
            margin-top:8px;
        }
    }
    .home-module{
        padding-bottom: 80px;
        margin:0 auto;
    }
    .home-research-module{
        background:#F6F8FC;
    }
    /deep/.home-more{
        color:#fff;
        background:#0256FF;
        width:128px;
        height:48px;
        line-height: 48px;
        outline:none;
        cursor: pointer;
        appearance: none;
        border:none;
        font-size: 16px;
        &:hover{
            background:#024DE5;
        }
    }
    /deep/.module-container{
        width: 1200px;
        margin:0 auto;
    }
    .home-partner-module{
        .home-title{
            border-top: 1px solid rgba(216,216,216,.45px);
        }
    }
    .partner-content{
        width:1200px;
        margin:40px auto 0;
        display: flex;
        flex-wrap: wrap;
        img{
            width:300px;
            height:225px;
        }
    }
}
</style>