<!-- 
 * @description: 新闻
 * @fileName: News.vue  
 * @date: 2022-08-19 17:53:27
!-->
<template>
    <div class="home-news module-container">
        <div class="news-content">
            <div class="news-left">
                <div class="news-lately">
                    <div class="news-lately-img">
                        <img :src="newList[0]?.articleCover"/>
                    </div>
                    <div class="lately-time">
                        <p class="new-date">{{$dayjs(newList[0]?.publishTime).format("MM/DD")}}</p>
                        <p class="new-year">{{$dayjs(newList[0]?.publishTime).format("YYYY")}}</p>
                    </div>
                </div>
                <p class="articleTitle" @click="handleClick(newList[0])">{{newList[0]?.articleTitle}}</p>
                <p class="articleAbstract" @click="handleClick(newList[0])">{{newList[0]?.articleAbstract}}</p>
            </div>
            <div class="news-right">
                <div class="news-list" v-for="(item,index) in newList.slice(1,4)" :key="index">
                    <div class="item-left">
                        <p class="new-date">{{$dayjs(item.publishTime).format("MM/DD")}}</p>
                        <p class="new-year">{{$dayjs(item.publishTime).format("YYYY")}}</p>
                    </div>
                    <div class="item-right">
                        <p class="articleTitle" @click="handleClick(item)">{{item.articleTitle}}</p>
                        <p class="articleAbstract" @click="handleClick(item)">{{item.articleAbstract}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="news-footer">
            <button class="home-more" @click="seeMore">查看更多</button>
        </div>
    </div>
</template>
<script>
export default {
    name:'HomeNews',
    data(){
        return{
            newList:[]
        }
    },
    created() {
        this.getArticleInfo();
    },
    methods:{
         handleClick(item){
            window.open(window.location.origin + `/newcms/detail?articleId=${item.id}`)
        },
        seeMore(){
            this.$router.push({
                path:'/xwdt'
            })
        },
        getArticleInfo(){
            const data = {
                className:'新闻动态',
                saasId:localStorage.getItem('saasId') ? localStorage.getItem('saasId') : '',
                sort:1,
                pageNum:1,
                pageSize:4
            }
            this.$api.industrycollege.getArticleInfo(data).then(res => {
                if(res.success){
                    this.newList = res.data.list
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.home-news{
   .news-content{
        margin-top:10px;
        display: flex;
        justify-content: space-between;
    }
    .news-left{
        width:440px;
        padding-left:105px;
    }
    .news-lately{
        width:440px;
        position: relative;
        width:440px;
        height:330px;
        margin-bottom:16px;
        .news-lately-img{
            position: absolute;
            top:0;
            left: 0;
            width:100%;
            height:100%;
            z-index:3;
        }
        .lately-time{
            position: absolute;
            left:-105px;
            top:-68px;
            background:#0256FF;
            width:120px;
            height:96px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            flex-direction: column;
            padding-top: 12px;
            align-items: center;
            z-index:2;
            p{
                color:#fff;
            }
        }
    }
    .new-date{
        font-size:30px;
        line-height: 38px;
        color: rgba(51,51,51,0.451);
        margin-bottom:8px;
    }
    .new-year{
        font-size:18px;
        line-height: 26px;
        color: rgba(51,51,51,0.451);
    }
    .articleTitle{
        height:56px;
        font-size:20px;
        line-height:28px;
        color: rgba(51,51,51);
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
        cursor: pointer;
        &:hover{
            color:#0256FF;
        }
    }
    .articleAbstract{
        cursor: pointer;
        margin-top:16px;
        line-height: 22px;
        font-size: 14px;
        color: rgba(51,51,51,0.651);
        margin-top: 16px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
        &:hover{
            opacity: .8;
        }
    }
    .news-left{
        cursor: pointer;
        padding-top:103px;
        img{
            width:440px;
            height:330px;
            display: block;
            margin-bottom:16px;
        }
    }
    .news-right{
        width:594px;
        .news-list{
            display: flex;
            padding-bottom:16px;
            padding-top:48px;
            border-bottom:1px solid #D8D8D8;
            .articleAbstract{
                -webkit-line-clamp: 2; // 超出多少行
                line-height:26px;
            }
            .item-left{
                text-align:center;
            }
            .item-right{
                margin-left: 36px;
                width:470px;
            }
        }
    }
    .news-footer{
        text-align:center;
        margin-top:90px;
    }
}
</style>
