<!-- 
 * @description:研究成果
 * @fileName: Research.vue 
 * @date: 2022-08-19 17:53:50
!-->
<template>
    <div class="home-research module-container">
        <div class="research-content">
            <div class="research-list">
                <div class="research-item" v-for="(item,index) in list" :key="index">
                    <template v-if="index%2==0">
                        <p class="time"></p>
                        <p class="title" @click="handleClick(item)">{{item.articleTitle}}</p>
                    </template>
                    <template v-else>
                        <p class="title" @click="handleClick(item)">{{item.articleTitle}}</p>
                        <p class="time"></p>
                    </template>
                </div>
            </div>
        </div>
        <div class="research-footer">
            <button class="home-more" @click="seeMore">查看更多</button>
        </div>
    </div>
</template>
<script>
export default {
    name:'HomeResearch',
    data(){
        return{
            list:[]
        }
    },
    methods: {
        seeMore(){
            this.$router.push({
                path:'/cgzs'
            })
        },
        handleClick(item){
            window.open(window.location.origin + `/newcms/detail?articleId=${item.id}`)
        },
        getArticleInfo(){
            const data = {
                className:'成果展示',
                saasId:localStorage.getItem('saasId') ? localStorage.getItem('saasId') : '',
                sort:1,
                pageNum:1,
                pageSize:5
            }
            this.$api.industrycollege.getArticleInfo(data).then(res => {
                if(res.success){
                    if(res.data?.list){
                        this.list = res.data.list.sort((a,b)=>{
                            return Date.parse(b.publishTime) - Date.parse(a.publishTime)
                        })
                    }
                }
            })
        },
    },
    created() {
        this.getArticleInfo();
    },
}
</script>
<style lang="less" scoped>
.home-research{
    margin-top:86px;
    .title{
        cursor: pointer;
    }
    .research-content{
        margin-top:86px;
        position: relative;
        &::after{
            position:absolute;
            left:50%;
            transform: translateX(-50%);
            width:0;
            top:40px;
            height:444px;
            content:'';
            z-index:8;
            opacity: 0.3;
            border-right:3px dashed #B3B2B2;
        }
    }
    .research-footer{
        text-align:center;
    }
    .research-item{
        margin-bottom:60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position:relative;
        height:56px;
        &::after{
            position:absolute;
            left:50%;
            top:50%;
            width:30px;
            height:30px;
            content:'';
            z-index:9;
            background:#0256FF;
            border-radius:50%;
            transform: translate(-50%,-50%);
        }
        &:nth-child(2){
             &::after{
                 width:25px;
                 height:25px;
             }
        }
        &:nth-child(3){
             &::after{
                 width:20px;
                 height:20px;
             }
        }
        &:nth-child(4){
             &::after{
                 width:15px;
                 height:15px;
             }
        }
        &:nth-child(5){
             &::after{
                 width:10px;
                 height:10px;
             }
        }
        p{
            width:427px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box;
            -webkit-line-clamp: 2; // 超出多少行
            -webkit-box-orient: vertical;
            &:nth-child(1){
                text-align: right;
                margin-right:40px;
            }
            &:nth-child(2){
                margin-left:40px;
            }
        }
        .time{
            color: rgba(51,51,51,0.651);
        }
        .title{
            &:hover{
                color:#0256FF;
            }
        }
    }
}
</style>

