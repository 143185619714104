<!-- 
 * @description: 专家团队
 * @fileName: Expert.vue 
 * @date: 2022-08-19 18:05:20
!-->
<template>
    <div class="home-expert module-container">
        <div class="swiper-container">
            <div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in expertList" :key="index">
					<div class="swiper-item-content">
						<img :src="item.src"/>
						<p class="expert-name">{{item.name}}</p>
						<p class="expert-intro">{{item.intro}}</p>
						<p class="expert-detail">{{item.detail}}</p>
					</div>
				</div>
            </div>
        </div>
		<div class="swiper-arrow prev-arrow swiper-button-prev">
			<svg-icon icon-class="arrow-left-circle-line"></svg-icon>
		</div>
		<div class="swiper-arrow next-arrow swiper-button-next">
			<svg-icon icon-class="arrow-right-circle-line"></svg-icon>
		</div>
    </div>
</template>
<script>
import Swiper from "swiper";
export default {
    name:'HomeExpert',
    data(){
        return{
            list:[
                {
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/expert1.png'
                }
			],
			     expertList:[
                {
                    name:'王端瑞',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader1.png',
                    intro:'国际互联网教育研究院联席院长',
                    detail:'伟东云教育集团董事长、香港工商总会会长、中华海外联谊会理事、山东省慈善总会荣誉会长'
                },{
                    name:'祝智庭',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader2.png',
                    intro:'国际互联网教育研究院联席院长',
                    detail:'华东师范大学终身教授、教育信息化系统工程研究中心主任、教育部教育信息化技术标准委员会主任'
                },{
                    name:'汤子海',
                    intro:'国际互联网教育研究院常务副院长',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader3.png',
                    detail:'全国信息技术标准化技术委员会教育技术分技术委员会副秘书长，国家发展改革委PPP专家'
                },{
                    name:'沈岱',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader4.png',
                    intro:'国际互联网教育研究院常务副院长',
                    detail:'DEMOS培训教育集团全球总裁，伟东云教育集团副总裁'
                },{
                    name:'郑莉',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader5.png',
                    intro:'专家委员会专家',
                    detail:'清华大学计算机科学与技术系教授，国家级教学团队骨干，IS0/IEC JTCl SC36国际标准组织…'
                },{
                    name:'李玉顺',
                    intro:'专家委员会专家',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader6.png',
                    detail:'北京师范大学教育学部教育技术学院副教授，数字学习与教育公共服务教育部工程研究中心…'
                },{
                    name:'钱冬明',
                    intro:'专家委员会专家',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader7.png',
                    detail:'华东师范大学开放教育学院副教授、华东师大教育信息化系统工程研究中心常务副主任…'
                },
                {
                    name:'张福涛',
                    intro:'专家委员会专家',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/leader8.png',
                    detail:'山东省昌乐一中常务副校长，先后被评为全国课改杰出校长、全国新课程实施先进个人、全国…'
                }
            ]
        }
    },
    methods: {
        initSwiper(){
            this.swiper = new Swiper(".swiper-container", {
				slidesPerView : 4,
				spaceBetween : 40,
				autoplay:true,
				loop:true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
            })
        }
    },
    mounted() {
        this.initSwiper();
    }
         
}
</script>
<style lang="less" scoped>
	.swiper-arrow{
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		z-index: 99;
		color:rgba(51,51,51,.15);
		.icon{
			font-size:60px;
			margin-top:32px;
			&:hover{
				color:rgba(51,51,51,.45);
			}
		}
	}
	.prev-arrow{
		left:-0px;
	}
	.next-arrow{
		right:0;
	}
    .home-expert{
		margin-top: 40px!important;
		width:1200px;
		position: relative;
	}
	.swiper-button-prev,.swiper-button-next{
		background:none;
	}
	.swiper-container{
		width:1080px;
		margin:0 auto;
		position: relative;
		overflow: hidden;
		padding-left: 16px;
		padding-right: 16px;
		box-sizing: border-box;
	}
    .swiper-wrapper{
        position: relative;
        width: 100%;
		height: 100%;
        z-index: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
		display: flex;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: transform;
        transition-property: transform;
        transition-property: transform,-webkit-transform;
        -webkit-box-sizing: content-box;
		box-sizing: content-box;
		margin-bottom:10px;
    }
    .swiper-slide {
		transition-timing-function:linear;
		padding-top:60px;
		position: relative;
    }
	.swiper-slide img{
		width:120px;
		height:120px;
		border-radius: 4px;
		position: absolute;
		left:50%;
		transform: translateX(-50%);
		top:0;
	}
	.swiper-item-content{
		width:234px;
		padding-bottom:50px;
		text-align: center;
		padding-left: 16px;
		padding-right: 16px;
		box-sizing: border-box;
		padding-top:76px;
		border-radius:8px;
		height: 310px;
		box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
	}
	.expert-intro{
		font-size: 16px;
		line-height: 24px;
		height:48px;
		margin-top: 8px;
		margin-bottom: 40px;
		padding:0 13px;
	}
	.expert-detail{
		font-size: 14px;
		line-height: 22px;
		color: rgba(51,51,51,0.651);
		overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 3; // 超出多少行
        -webkit-box-orient: vertical;
	}
	.expert-name{
		font-size: 24px;
		line-height: 32px;
	}
</style>

