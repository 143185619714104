<template>
    <div class='homeBanner'>
       <el-carousel arrow="never" >
         <el-carousel-item v-for="(item, index) in imgList" :key="index" >
             <img :src="item.imgUrl" alt="">
         </el-carousel-item>
         <img :src="imgHeight" class="toolImg" alt="">
       </el-carousel>
    </div>
</template>
   
<script>
export default {
    name:'HomeBanner',
    data () {
        return {
            imgList: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/banner1.png',
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/banner2.png',
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/banner3.png',
                },
            ],
            imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/banner1.png'
        };
    }
}

</script>
<style lang='stylus' scoped>
    @import "../assets/css/homepageBanner"
    .toolImg{
      width:100%;
    }
  /deep/.el-carousel__button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  /deep/.el-carousel__container{
    height:100%!important;
  }
  .el-carousel__item{
    img{
      width 100%;
    }
  }
  /deep/ .el-carousel__indicators--horizontal{
    bottom:20px;
    .el-carousel__indicator--horizontal{
      padding:0 8px;
     }
  }
</style>